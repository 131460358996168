import * as moment from 'moment';

export function downloadFile(content, name) {
  const url = URL.createObjectURL(new Blob([content]));
  let j = document.createElement('a');
  j.download = name || `file_${Date.now()}.txt`;
  j.href = url;
  j.click();
}

export const formatDateSql = date => {
  if (!date) {
    return '';
  }
  return moment(date).format('YYYY-MM-DD');
};

export function dateToDb(dt, utc = false) {
  let r;
  if (dt) {
    r = moment(dt);
    if (utc) {
      r = r.utc();
    }
    return r.format('YYYY-MM-DD HH:mm:ss');
  } else {
    return dt;
  }
}

export const readFileAsString = file => {
  return new Promise(resolve => {
    var reader = new FileReader();
    reader.onload = function (event) {
      resolve(event.target.result);
    };
    reader.readAsText(file);
  });
};

const UNITS = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte', 'petabyte'];
const BYTES_PER_KB = 1000;

/**
 * Format bytes as human-readable text.
 * @param sizeBytes Number of bytes.
 * @return Formatted string.
 */
export function humanFileSize(sizeBytes) {
  let size = Math.abs(Number(sizeBytes));
  let u = 0;
  while (size >= BYTES_PER_KB && u < UNITS.length - 1) {
    size /= BYTES_PER_KB;
    ++u;
  }
  return new Intl.NumberFormat([], {
    style: 'unit',
    unit: UNITS[u],
    unitDisplay: 'short',
    maximumFractionDigits: 1,
  }).format(size);
}

export function moveItemUp(arr, index) {
  if (index > 0 && index < arr.length) {
    const temp = arr[index - 1];
    arr[index - 1] = arr[index];
    arr[index] = temp;
  }
}

export function moveItemDown(arr, index) {
  if (index >= 0 && index < arr.length - 1) {
    const temp = arr[index + 1];
    arr[index + 1] = arr[index];
    arr[index] = temp;
  }
}

/**
 * Promise like object that allows to resolve it promise from outside code. Example: 
 * 
```
class Api {
  fooReady = new Deferred<Data>()
  private knower() {
    inOtherMoment(data=>{
      this.fooReady.resolve(data)
    })
  }
}
```
 */
export class Deferred {
  resolve(r) {}
  reject(r) {}
  promise; /* : Promise<R> */
  status /* : 'resolved' | 'pending' | 'rejected'*/;
  constructor(
    callback /* ?: (this: Deferred<R, J>, resolve: (r: R) => void, reject?: (r: J) => void) => void */
  ) {
    let instance = this;
    this.resolve = null;
    this.reject = null;
    this.status = 'pending';
    this.promise = new Promise(function (resolve, reject) {
      instance.resolve = function () {
        this.status = 'resolved';
        resolve.apply(this, arguments);
      };
      instance.reject = function () {
        this.status = 'rejected';
        reject.apply(this, arguments);
      };
    });
    if (typeof callback === 'function') {
      callback.call(this, this.resolve, this.reject);
    }
  }
  then(resolve /* : (r: R) => void */) {
    return this.promise.then(resolve);
  }
  catch(r /* : (reject: J) => void*/) {
    return this.promise.catch(r);
  }
}
