import { CButton, CCol, CSpinner } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as errorModalActions from '../../store/actions/errorModalActions';
import * as roomsActions from '../../store/actions/roomsActions';
import { BackToRoomTypesList } from '../rooms/RoomCommonComponents';
import { RoomTypeComponent } from './RoomTypeComponent';
import { RoomTypeJSONEditor } from './RoomTypeJSONEditor';
import { RoomTypePreview } from './RoomTypePreview';
import { getAllTags } from '../../store/actions/tagActions';
import { Link } from 'react-router-dom';
import { RoomTypePoEditor } from './RoomTypePoEditor';
import { toast } from 'react-toastify';
import { toastDefaultConfig } from '../../constants/ToastDefault';

export const RoomTypeView = props => {
  const dispatch = useDispatch();
  const roomTypeId = props.match.params.roomTypeId;
  const roomTypes = useSelector(state => state.rooms.roomTypes);
  let roomType = (roomTypes || []).find(rt => rt.id == roomTypeId);
  console.log('RoomTypeView', { roomType });
  const [tab, setTab] = useState('details');
  let data = { ...roomType };
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateErrors, setUpdateErrors] = useState(null);

  useEffect(() => {
    dispatch(roomsActions.getRoomTypes());
    dispatch(roomsActions.getRoomPreferenceOptions()); // this is needed for field list options editor
    dispatch(getAllTags()); // this is needed for field tag options editor
  }, []);

  const saveRoomType = async () => {
    setLoading(true);
    const toastId = toast.loading('Saving...', toastDefaultConfig);
    try {
      await dispatch(roomsActions.updateRoomType(data));
      toast.update(toastId, {
        ...toastDefaultConfig,
        render: 'Saved!',
        type: 'success',
        isLoading: false,
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setUpdateErrors(error.response.data.errors);
      }
      console.error('err', error);
      dispatch(
        errorModalActions.showModal('Something went wrong updating room type, see errors below.')
      );
      toast.update(toastId, {
        ...toastDefaultConfig,
        render: 'Error updating room type',
        type: 'error',
        isLoading: false,
      });
    }
    setLoading(false);
    setChanged(false);
  };

  const cancel = () => {
    window.location.reload();
  };

  const onChange = e => {
    setChanged(true);
    setUpdateErrors(null);
    data = e;
  };

  if (roomTypeId && roomTypes && roomTypes.length && !roomType) {
    dispatch(errorModalActions.showModal(`Room type id ${roomTypeId} not found`));
  }

  if (!roomType) {
    return <CSpinner />;
  }

  return (
    <CCol md="12">
      <BackToRoomTypesList />

      <h3>
        <strong>"{roomType.name}"</strong> room type
      </h3>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <nav
          className="navbar navbar-expand navbar-light bg-light"
          style={{ paddingLeft: 0, backgroundColor: '#e3f2fd', fontSize: '1.4em' }}
        >
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto" style={{ paddingLeft: 0 }}>
              <li className={`nav-item ${tab === 'details' ? 'active' : ''}`}>
                <a
                  className="nav-link"
                  href="#"
                  onClick={() => setTab('details')}
                  style={{ paddingLeft: 0 }}
                >
                  Editor
                </a>
              </li>
              {/* <li className={`nav-item ${tab === 'json' ? 'active' : ''}`}>
                <a className="nav-link" href="#" onClick={() => setTab('json')} style={{ paddingLeft: 0 }}>
                  JSON
                </a>
              </li> */}
              <li className={`nav-item ${tab === 'preview' ? 'active' : ''}`}>
                <a className="nav-link" href="#" onClick={() => setTab('preview')}>
                  Preview
                </a>
              </li>
              <li className={`nav-item ${tab === 'poeditor' ? 'active' : ''}`}>
                <a className="nav-link" href="#" onClick={() => setTab('poeditor')}>
                  PoEditor
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <div style={{ display: 'flex' }}>
          {loading && <CSpinner />}
          <CButton
            color="primary"
            disabled={!changed}
            style={{ maxWidth: '200px', height: 40, marginLeft: 8, marginBottom: 15 }}
            onClick={saveRoomType}
          >
            Save
          </CButton>
          <CButton
            color="secondary"
            disabled={!changed}
            style={{ maxWidth: '200px', height: 40, marginLeft: 8, marginBottom: 15 }}
            onClick={cancel}
          >
            Cancel
          </CButton>

          <CButton
            color="secondary"
            style={{ maxWidth: '200px', height: 40, marginLeft: 8, marginBottom: 15 }}
            onClick={() => {
              alert('NOT IMPLEMENTED YET');
            }}
          >
            Clone
          </CButton>

          <CButton
            color="danger"
            style={{ maxWidth: '200px', height: 40, marginLeft: 8, marginBottom: 15 }}
            onClick={() => {
              alert('NOT IMPLEMENTED YET');
            }}
          >
            Delete
          </CButton>
        </div>
      </div>

      {updateErrors && (
        <div style={{ backgroundColor: '#ffcccc' }}>
          <h4>Update Errors</h4>
          {updateErrors.map(error => (
            <div>
              <p>
                <strong>Reason: </strong> {error.message}
              </p>
              {error.type === 'active-rooms' ? (
                <div>
                  The following active rooms are using this room-type, must be deactivated before
                  changing this room type.
                  <ul>
                    {error.details.map(({ roomId }) => (
                      <li>
                        <Link
                          to={{
                            pathname: `/main/rooms/${roomId}`,
                          }}
                          className="link"
                          style={{ color: '#4A90E2' }}
                        >
                          {roomId}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      )}

      {tab === 'details' && (
        <div>
          <RoomTypeComponent
            roomType={{ ...roomType, fields: roomType.schema.fields }}
            onChange={onChange}
          />
        </div>
      )}

      {tab === 'preview' && (
        <div>
          <RoomTypePreview roomType={roomType.schema} />
        </div>
      )}

      {/* {tab === 'json' && <div>
        <RoomTypeJSONEditor roomType={roomType} onChange={onChange} />
      </div>
      } */}

      {tab === 'poeditor' && (
        <div>
          <RoomTypePoEditor roomType={roomType} />
        </div>
      )}
    </CCol>
  );
};
