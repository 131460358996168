export const RoomStatus = {
  /** inactive room which already ended */
  ended: 'ended',
  /** shown but not started */
  shownNotActive: 'shownNotActive',
  /** the room is currently available for joining: started and not ended */
  active: 'active',
  /** future rooms that are not currently even shown */
  future: 'future',
};

export const RoomStatusNames = {
  ended: 'Ended',
  shownNotActive: 'Shown but not active',
  active: 'Active',
  future: 'Future',
};

export const DeleteRoomAdjective = {
  inactive: 'inactive',
  future: 'future',
  ended: 'ended',
};

// TODO remove this enum - this is dynamic now
export const RoomType = {
  1: 'default',
};

export const RoomTypeField = {
  tag: 'tag',
  list: 'list',
  // 'text': 'text',
};
