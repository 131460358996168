import { CButton, CCard, CSpinner } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as roomsActions from '../../store/actions/roomsActions';
import { moveItemDown, moveItemUp } from '../../utils/utils';
import { Field } from '../rooms/RoomCommonComponents';
import { CreateRoomTypeField } from './CreateRoomTypeField';
import { FieldComponent } from './fields/FieldComponent';

export const RoomTypeComponent = props => {
  const dispatch = useDispatch();
  const roomType = props.roomType;

  // a state with all fields modification to be used on save action
  const [editorFields, setEditorFields2] = useState({});

  function setEditorFields(data) {
    setEditorFields2(data);
    props.onChange && props.onChange(data);
  }

  useEffect(() => {
    setEditorFields2(roomType);
  }, [roomType]);

  function updateEditorField(id, value) {
    // if it doesn't have seconds we add it so date-format doesn't fail
    if (id.toLowerCase().endsWith('date') && value.match(/T\d\d:\d\d$/)) {
      value = `${value}:00`;
    }
    const data = { ...editorFields, ...{ [id]: value } };
    setEditorFields(data);
  }

  const onInputChangeBuilder = name => e => {
    updateEditorField(name, e.target.value);
  };

  function fieldProps(roomType, key, value = null) {
    return {
      name: key,
      value: value || roomType[key],
      editable: roomType.editable,
      onChange: onInputChangeBuilder(key),
    };
  }

  function fieldFieldProps(field, key, value = null) {
    return {
      name: key,
      value: value || field[key],
      editable: field.editable,
      onChange: e => {
        const f = editorFields.fields.find(f => f.name === field.name);
        if (!f) {
          return;
        }
        f[key] = e.target.value;
        setEditorFields({ ...editorFields });
      },
    };
  }

  const addField = data => {
    roomType.schema.fields = [...(roomType.schema.fields || []), { ...data, options: [] }];
    dispatch(roomsActions.updateRoomTypeState(roomType));
    props.onChange && props.onChange(roomType);
  };

  const onFieldMove = ({ direction, index }) => {
    if (direction === 'up') {
      moveItemUp(roomType.schema.fields, index);
    }
    if (direction === 'down') {
      moveItemDown(roomType.schema.fields, index);
    }
    dispatch(roomsActions.updateRoomTypeState(roomType));
    props.onChange && props.onChange(roomType);
  };

  const onDeleteField = ({ index }) => {
    roomType.schema.fields.splice(index, 1);
    dispatch(roomsActions.updateRoomTypeState(roomType));
    props.onChange && props.onChange(roomType);
  };

  if (!roomType) {
    return <CSpinner />;
  }

  return (
    <div>
      <Field {...fieldProps(roomType, 'name')} editable />
      <Field {...fieldProps(roomType, 'description')} editable />

      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <strong>Fields</strong>
          </div>
          <div>
            <CreateRoomTypeField onCreated={addField} />
          </div>
        </div>

        {roomType.fields.map((field, index) => (
          <CCard className={['pl-3'].join(' ')} style={{ padding: 6 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <strong>{field.name}</strong> ({field.type})
              </div>
              <div>
                <CButton
                  color="secondary"
                  size="sm"
                  style={{ marginRight: 6 }}
                  disabled={index === 0}
                  onClick={() => onFieldMove({ direction: 'up', index })}
                >
                  up
                </CButton>
                <CButton
                  color="secondary"
                  size="sm"
                  style={{ marginRight: 6 }}
                  disabled={index === (roomType.fields || []).length - 1}
                  onClick={() => onFieldMove({ direction: 'down', index })}
                >
                  down
                </CButton>
                <CButton color="danger" size="sm" onClick={() => onDeleteField({ index })}>
                  delete
                </CButton>
              </div>
            </div>
            <FieldComponent
              field={field}
              fieldProps={fieldFieldProps}
              roomTypeId={roomType.id}
              onChange={roomType => {
                props.onChange && props.onChange(roomType);
              }}
            />
          </CCard>
        ))}
      </div>
    </div>
  );
};
