import {
  GET_ROOMS,
  GET_ROOM,
  UPDATE_ROOM,
  DELETE_ROOM,
  DELETE_ROOMS,
  GET_ROOM_TYPES,
  GET_ROOM_PREFERENCE_OPTIONS,
  CREATE_ROOM_TYPE,
  UPDATE_ROOM_TYPE,
  CREATE_ROOM_TYPE_PREFERENCE_OPTION,
  CLONE_ROOM,
  GET_ACTIVE_PLACES,
} from '../actions/roomsActions';
import { DeleteRoomAdjective, RoomStatus } from '../../views/rooms/RoomTypes';

const initialState = {
  rooms: [],
  roomTypes: [],
  roomPreferenceOptions: [],
  moreRoomsLeft: true,
  currentPage: 0,
  status: '',
  roomType: '',
  orderBy: 'showDate',
  orderDirection: 'asc',
  total: 0,
  limit: 25,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ROOMS:
      return {
        ...state,
        ...{
          rooms: action.rooms,
          currentPage: action.currentPage,
          status: action.status,
          roomType: action.roomType,
          moreRoomsLeft: action.moreRoomsLeft,
          orderBy: action.orderBy,
          orderDirection: action.orderDirection,
          total: action.total,
          limit: action.limit,
        },
      };

    case GET_ROOM:
      return {
        ...state,
        ...{
          room: action.room,
        },
      };

    case CLONE_ROOM:
      return {
        ...state,
        ...{
          room: { ...action.room, clone: true },
        },
      };

    case UPDATE_ROOM:
      return {
        ...state,
        ...{
          room: action.room,
        },
      };

    case DELETE_ROOM:
      return {
        ...state,
        ...{
          rooms: state.rooms.filter(r => r.id === action.roomId),
        },
      };

    case DELETE_ROOMS:
      return {
        ...state,
        ...{
          rooms: state.rooms.filter(r =>
            action.adjective === DeleteRoomAdjective.future
              ? r.status !== RoomStatus.future
              : action.adjective === DeleteRoomAdjective.ended
                ? r.status !== RoomStatus.ended
                : action.adjective === DeleteRoomAdjective.inactive
                  ? ![RoomStatus.future, RoomStatus.ended].includes(r.status)
                  : false
          ),
        },
      };

    case GET_ROOM_TYPES:
      return {
        ...state,
        ...{
          roomTypes: action.roomTypes,
        },
      };

    case UPDATE_ROOM_TYPE:
      const rt = state.roomTypes.find(rt => rt.id === action.roomType.id);
      Object.assign(rt, action.roomType);
      return {
        ...state,
        roomTypes: [...state.roomTypes],
      };

    case GET_ROOM_PREFERENCE_OPTIONS:
      return {
        ...state,
        ...{
          roomPreferenceOptions: action.roomPreferenceOptions,
        },
      };

    case CREATE_ROOM_TYPE:
      return {
        ...state,
        ...{
          roomTypes: [...state.roomTypes, action.roomType],
        },
      };

    case CREATE_ROOM_TYPE_PREFERENCE_OPTION:
      return {
        ...state,
        ...{
          roomPreferenceOptions: [...state.roomPreferenceOptions, action.roomPreferenceOption],
        },
      };

    case GET_ACTIVE_PLACES:
      return {
        ...state,
        ...{
          activePlaces: action.activePlaces,
        },
      };

    default:
      return state;
  }
};
