import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as roomsActions from '../../store/actions/roomsActions';
import * as errorModalActions from '../../store/actions/errorModalActions';
import {
  CButton,
  CCard,
  CCol,
  CDataTable,
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CPagination,
  CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { Link } from 'react-router-dom';
import { RoomStatus, RoomStatusNames, RoomType } from './RoomTypes';
import printDateWithTz from '../../utils/dateUtils';

var moment = require('moment-timezone');

export const RoomsView = props => {
  const dispatch = useDispatch();
  const rooms = useSelector(state => state.rooms.rooms);
  const roomsState = useSelector(state => state.rooms);
  const [loading, setLoading] = useState(true);
  const selectedTimezone = useSelector(state => state.timezone.timezone);

  const fields = [
    {
      key: 'roomId',
      label: 'ID',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'name',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'description',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'showDate',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'startDate',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'endDate',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'activePlaceName',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'type',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'status',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
    },
    {
      key: 'audience',
      _style: { width: '1%', fontSize: '0.75rem', borderTop: '0px' },
      filter: false,
    },
  ];

  const onStatusChange = async ({ target }) => {
    const selection = Array.from(target.options)
      .filter(option => option.selected)
      .map(option => option.value);
    const value = selection.includes('all') ? '' : selection.filter(v => v);
    try {
      setLoading(true);
      await dispatch(roomsActions.getRooms({ status: value }));
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
  };

  const onTypeChange = async ({ target }) => {
    alert('not implemented');
    // TODO: similar to onStatusChange
  };

  const onPageChange = async page => {
    try {
      setLoading(true);
      await dispatch(roomsActions.getRooms({ page: page - 1 }));
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
  };

  const onOrderChange = async ({ column, asc }) => {
    column = column || 'showDate';
    asc = asc ? 'asc' : 'desc';
    try {
      setLoading(true);
      await dispatch(
        roomsActions.getRooms({
          orderBy: column || 'showDate',
          orderDirection: asc || 'asc',
        })
      );
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
  };

  const fetchRoomsHandler = async () => {
    setLoading(true);
    try {
      await dispatch(roomsActions.getRooms());
    } catch (err) {
      dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
  };

  const onDeleteRooms = async adjective => {
    setLoading(true);
    try {
      await dispatch(roomsActions.deleteRooms(adjective));
    } catch (err) {
      dispatch(errorModalActions.showModal(err.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRoomsHandler();
  }, []);

  return (
    <CCol md="12">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>{loading && <CSpinner style={{ height: 30, margin: 0, padding: 0 }} />}</div>

        <div style={{ display: 'flex' }}>
          <CButton
            color="primary"
            style={{ maxWidth: '200px', height: 40, marginLeft: 8, marginBottom: 15 }}
            onClick={() => {
              alert('NOT IMPLEMENTED YET');
            }}
          >
            Create
          </CButton>

          <CButton
            color="primary"
            style={{ maxWidth: '200px', height: 40, marginLeft: 8, marginBottom: 15 }}
            onClick={() => {
              window.location.replace('/main/rooms-import');
            }}
          >
            Import CSV
          </CButton>

          {/*<CDropdown>*/}
          {/*  <CDropdownToggle*/}
          {/*    color="danger"*/}
          {/*    style={{ maxWidth: '200px', height: 40, marginLeft: 8, marginBottom: 15 }}*/}
          {/*  >*/}
          {/*    Delete*/}
          {/*  </CDropdownToggle>*/}
          {/*  <CDropdownMenu>*/}
          {/*    <CDropdownItem*/}
          {/*      href="#"*/}
          {/*      onClick={e => {*/}
          {/*        if (window.confirm('Are you sure you want to delete ALL future rooms ?')) {*/}
          {/*          onDeleteRooms('future');*/}
          {/*        }*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      Future rooms*/}
          {/*    </CDropdownItem>*/}
          {/*    <CDropdownItem*/}
          {/*      href="#"*/}
          {/*      onClick={e => {*/}
          {/*        if (window.confirm('Are you sure you want to delete ALL ended rooms ?')) {*/}
          {/*          onDeleteRooms('ended');*/}
          {/*        }*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      Ended rooms*/}
          {/*    </CDropdownItem>*/}

          {/*    <CDropdownItem*/}
          {/*      href="#"*/}
          {/*      onClick={e => {*/}
          {/*        if (*/}
          {/*          window.confirm(*/}
          {/*            'Are you sure you want to delete ALL inactive rooms ? (this is both future and ended rooms) ?'*/}
          {/*          )*/}
          {/*        ) {*/}
          {/*          onDeleteRooms('inactive');*/}
          {/*        }*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      All inactive rooms*/}
          {/*    </CDropdownItem>*/}
          {/*  </CDropdownMenu>*/}
          {/*</CDropdown>*/}
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <span>
            {`${roomsState.currentPage * roomsState.limit + 1}-${Math.min((roomsState.currentPage + 1) * roomsState.limit, roomsState.total)} of ${roomsState.total}`}
          </span>
          <CPagination
            activePage={roomsState.currentPage + 1}
            pages={Math.trunc(roomsState.total / roomsState.limit) + 1}
            onActivePageChange={onPageChange}
          ></CPagination>
        </div>

        <div style={{ display: 'flex' }}>
          <div className="form-group" style={{ marginLeft: '16px' }}>
            <div style={{ ...(roomsState.status?.length ? { color: 'rgb(198, 107, 50)' } : {}) }}>
              <CIcon name="cil-filter" />
              <label style={{ marginBottom: '2px', marginLeft: '5px' }}>Status</label>
            </div>
            <CDropdown title="Filter by status (multiple)">
              <CDropdownToggle style={{ border: '1px solid rgb(216, 219, 224)' }}>
                {roomsState.status?.length ? 'Filtered status' : 'All status'}
              </CDropdownToggle>
              <CDropdownMenu style={{ padding: 0 }}>
                <select
                  aria-label="Status"
                  id="status-select"
                  multiple
                  defaultValue={['all']}
                  value={roomsState.status.length ? roomsState.status : ['all']}
                  size={Object.keys(RoomStatus).length + 1}
                  onChange={onStatusChange}
                  style={{ width: '100%' }}
                >
                  <option value="all">All status</option>
                  {Object.keys(RoomStatus).map(status => (
                    <option key={status} value={status}>
                      {RoomStatusNames[status]}
                    </option>
                  ))}
                </select>
              </CDropdownMenu>
            </CDropdown>
            {!!roomsState.status?.length && (
              <CButton
                style={{ padding: 0 }}
                onClick={e => {
                  const select = document.querySelector('#status-select');
                  Array.from(select.options).forEach((option, i) => {
                    option.selected = i === 0 ? true : false;
                  });
                  onStatusChange({ target: select });
                }}
              >
                <CIcon name="cil-x"></CIcon>
                clear
              </CButton>
            )}
          </div>

          <div className="form-group" style={{ marginLeft: '16px' }}>
            <div style={{ ...(roomsState.type?.length ? { color: 'rgb(198, 107, 50)' } : {}) }}>
              <CIcon name="cil-filter" />
              <label style={{ marginBottom: '2px', marginLeft: '5px' }}>type</label>
            </div>
            <CDropdown title="Filter by type (multiple)">
              <CDropdownToggle style={{ border: '1px solid rgb(216, 219, 224)' }}>
                {roomsState.type?.length ? 'Filtered type' : 'All type'}
              </CDropdownToggle>
              <CDropdownMenu style={{ padding: 0 }}>
                <select
                  aria-label="type"
                  id="type-select"
                  multiple
                  defaultValue={['all']}
                  value={roomsState.type?.length ? roomsState.type : ['all']}
                  size={Object.keys(RoomType).length + 1}
                  onChange={onTypeChange}
                  style={{ width: '100%' }}
                >
                  <option value="all">All type</option>
                  {Object.keys(RoomType).map(roomType => (
                    <option key={roomType} value={roomType}>
                      {RoomType[roomType]}
                    </option>
                  ))}
                </select>
              </CDropdownMenu>
            </CDropdown>
            {!!roomsState.type?.length && (
              <CButton
                style={{ padding: 0, marginBottom: '32px' }}
                onClick={e => {
                  const select = document.querySelector('#type-select');
                  Array.from(select.options).forEach((option, i) => {
                    option.selected = i === 0 ? true : false;
                  });
                  onTypeChange({ target: select });
                }}
              >
                <CIcon name="cil-x"></CIcon>
                clear
              </CButton>
            )}
          </div>
        </div>
      </div>

      <CCard className={['pl-3'].join(' ')}>
        <CDataTable
          onSorterValueChange={onOrderChange}
          size="sm"
          items={rooms}
          fields={fields}
          hover
          sorter={{ external: true }}
          scopedSlots={{
            roomId: room => (
              <td>
                <Link
                  to={{
                    pathname: `/main/rooms/${room.id}`,
                  }}
                  className="link"
                  style={{ color: '#4A90E2' }}
                >
                  {room.id}
                </Link>
              </td>
            ),
            name: room => <td>{room.name}</td>,
            description: room => <td>{room.description}</td>,
            showDate: room => <td>{printDateWithTz(room.showDate, selectedTimezone)}</td>,
            startDate: room => <td>{printDateWithTz(room.startDate, selectedTimezone)}</td>,
            endDate: room => <td>{printDateWithTz(room.endDate, selectedTimezone)}</td>,
            activePlaceName: room => <td>{room.activePlaceName}</td>,
            type: room => (
              <td>
                <Link
                  to={{
                    pathname: `/main/roomTypes/${room.type}`,
                  }}
                  className="link"
                  style={{ color: '#4A90E2' }}
                >
                  {room.type}
                </Link>
              </td>
            ),
            status: room => <td>{printStatus(room.status)}</td>,
            audience: room => <td>{(room.users || []).length ? 'Private' : 'Public'}</td>,
          }}
        />
      </CCard>
    </CCol>
  );
};

function printDate(date) {
  return moment(date).format('Y-MM-DD hh:mm');
}

function printStatus(status) {
  if (status === RoomStatus.ended) {
    return 'Ended';
  }
  if (status === RoomStatus.shownNotActive) {
    return 'Shown but not active';
  }
  if (status === RoomStatus.active) {
    return 'Active';
  }
  if (status === RoomStatus.future) {
    return 'Future';
  } else {
    return 'N/A';
  }
}
