import {
  CButton,
  CCol,
  CInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CSpinner,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as errorModalActions from '../../store/actions/errorModalActions';
import * as roomsActions from '../../store/actions/roomsActions';
import { createCloneRoom } from '../../store/actions/roomsActions';
import { dateToDb, downloadFile, humanFileSize } from '../../utils/utils';
import { BackToRoomsList, Field } from './RoomCommonComponents';
import { RoomStatus } from './RoomTypes';
import { toast } from 'react-toastify';
import { toastDefaultConfig } from '../../constants/ToastDefault';
import ApiService from '../../services/ApiService';

export const RoomView = props => {
  const roomId = props.match.params.roomId;
  const baseButton = { maxWidth: '200px' };
  const dispatch = useDispatch();
  const room = useSelector(state => state.rooms.room);
  const roomTypes = useSelector(state => state.rooms.roomTypes) || [];
  const selectedTimezone = useSelector(state => state.timezone.timezone);

  const activePlaces = useSelector(state => state.rooms.activePlaces) || [];
  const [loading, setLoading] = useState(true);
  const [changed, setChanged] = useState(false);
  const [tab, setTab] = useState('details');
  const [file, setFile] = useState();
  const [report, setReport] = useState(null);
  const [currentUsers, setCurrentUsers] = useState(null);
  const [showAllowedList, setShowAllowedList] = useState(false);
  const [includeAllowedList, setIncludeAllowedList] = useState(false);

  const handleCheckboxChange = e => {
    setIncludeAllowedList(e.target.checked);
  };

  const toggleAllowedList = () => {
    setShowAllowedList(prevState => !prevState);
  };

  const handleFileChange = e => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      setFile(file);
      setReport(null);
    }
  };
  // a state with all fields modification to be used on save action
  const [editorFields, setEditorFields] = useState({});

  useEffect(() => {
    setEditorFields({});
  }, [room]);

  const [modalOpen, setModalOpen] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleDelete = () => {
    toggleModal();
  };

  const handleChange = event => {
    setDeleteMessage(event.target.value);
  };

  function updateEditorField(id, value) {
    // if it doesn't have seconds we add it so date-format doesn't fail
    if (id.toLowerCase().endsWith('date') && value.match(/T\d\d:\d\d$/)) {
      value = `${value}:00`;
    }
    const data = { ...editorFields, ...{ [id]: value } };
    setEditorFields(data);
  }

  const fetchRoomHandler = async () => {
    setLoading(true);
    try {
      await dispatch(roomsActions.getRoom(roomId));
    } catch (err) {
      dispatch(
        errorModalActions.showModal(`An error occurred getting room ${roomId}: ${err.message}`)
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchRoomHandler();
    dispatch(roomsActions.getRoomTypes());
    dispatch(roomsActions.getAllActivePlaces());
    return () => { };
  }, []);

  const onInputChangeBuilder = name => e => {
    updateEditorField(name, e.target.value);
    setChanged(true);
  };

  function fieldProps(room, key, value = null) {
    return {
      name: key,
      value: value || room[key],
      editable: canEditRoomProp(key, room) || room.clone,
      onChange: onInputChangeBuilder(key),
      timezone: selectedTimezone,
    };
  }

  const onActivePlaceChange = activePlaceId => {
    editorFields.activePlaceId = activePlaceId;
    setChanged(true);
  };

  function canEditRoomProp(name, room) {
    if (room.editable) {
      if (room.status === RoomStatus.active && name === 'startDate') {
        return false;
      }
    }
    return room.editable;
  }

  const onTypeChange = roomTypeId => {
    editorFields.type = roomTypeId;
    setChanged(true);
  };

  async function onSave() {
    const roomBody = { ...room, ...editorFields };
    Object.keys(roomBody).forEach(key => {
      if (roomBody[key] && key.toLowerCase().endsWith('date')) {
        if (!roomBody[key].endsWith('.000Z')) {
          roomBody[key] = roomBody[key] + '.000Z';
        }
        const formattedDate = dateToDb(roomBody[key], true);
        roomBody[key] = formattedDate;
      }
    });
    const toastId = toast.loading('Saving...', toastDefaultConfig);
    try {
      await dispatch(roomsActions.updateRoom(roomBody));
      toast.update(toastId, {
        ...toastDefaultConfig,
        render: 'Saved!',
        type: 'success',
        isLoading: false,
      });
    } catch (err) {
      dispatch(
        errorModalActions.showModal(`An error occurred saving room ${roomId}: ${err.message}`)
      );
      toast.update(toastId, {
        ...toastDefaultConfig,
        render: 'Error updating room',
        type: 'error',
        isLoading: false,
      });
    }
  }

  function onClone() {
    try {
      dispatch(roomsActions.cloneRoom(room));
    } catch (err) {
      dispatch(
        errorModalActions.showModal(`An error occurred cloning room ${roomId}: ${err.message}`)
      );
    }
  }

  async function onCloneCreate() {
    const roomBody = { ...room, ...editorFields };
    const toastId = toast.info(`Saving room ${roomBody.name}`, toastDefaultConfig);
    try {
      if (!includeAllowedList) {
        delete roomBody.users;
      }
      const result = await createCloneRoom(roomBody);
      if (result.status === 201) {
        toast.update(toastId, {
          ...toastDefaultConfig,
          render: `Room ${room.name} created successfully`,
          type: 'success',
          isLoading: false,
        });
        window.location.replace('/main/rooms/' + result.data.roomId);
      }
    } catch (err) {
      dispatch(
        errorModalActions.showModal(`An error occurred cloning room ${roomId}: ${err.message}`)
      );
      toast.update(toastId, {
        ...toastDefaultConfig,
        render: `Error creating room ${room.name}`,
        type: 'error',
        isLoading: false,
      });
    }
  }

  async function onDelete() {
    try {
      await dispatch(roomsActions.deleteRoom(room.id));
      window.location.replace('/main/rooms');
    } catch (err) {
      dispatch(
        errorModalActions.showModal(`An error occurred deleting room ${roomId}: ${err.message}`)
      );
    }
  }

  const deleteUserDisabled = currentUsers && currentUsers.length<=1

  async function onDeleteUser(userId) {
    if (!window.confirm('Are you sure you want to to remove user from room?')) {
      return
    }
    const toastId = toast.info(`Removing room user`, toastDefaultConfig);
    try {

      await ApiService.delete(`rooms/${roomId}/users/${userId}`, { withCredentials: true }, {});

      setCurrentUsers(currentUsers.filter(c => c.userId != userId))
      toast.update(toastId, {
        ...toastDefaultConfig,
        render: `Room user removed successfully`,
        type: 'success',
        isLoading: false,
      });
    } catch (err) {
      dispatch(
        errorModalActions.showModal(`An error occurred deleting room,user=${room.id},${userId} ${err.message}`)
      );
    }
  }


  function template() {
    downloadFile(
      `
userId
4a809e24-a954-4a67-9918-cd4f2fb0df07
0b669417-86f0-46d7-830f-7d7bc6a02fa6
4a809e24-a954-4a67-9918-cd4f2fb0df07
ed4f3725-7a5a-4e2b-8a71-7e0b8ec54c6b
  `.trim(),
      'motto-room-users-template.csv',
      'text/csv'
    );
  }

  const handleUploadClick = async () => {
    if (file.size > 3000000) {
      alert('Cannot upload files bigger than 3 MB. Aborting');
      return;
    }
    try {
      const results = await roomsActions.importRoomUsers(room.id, file);
      setReport(results);
      await dispatch(roomsActions.getRoom(roomId));
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
  };

  const onDeleteUsers = async () => {
    if (
      window.confirm(
        'Are you sure you want to delete all users from this room? It will became public'
      )
    ) {
      window.alert('NOT IMPLEMENTED');
    }
  };

  function isDeleteAllowed(roomId) {
    return deleteMessage !== `I want to delete room ${roomId}`;
  }

  const loadUsers = async () => {
    try {
      const results = await roomsActions.loadUsers(room.id);
      setCurrentUsers(results.users);
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
    }
  };

  if (!room || loading) {
    return (
      <div>
        <BackToRoomsList />
        <CSpinner />
      </div>
    );
  }

  const shouldShowRoomType =
    room.clone || (room.editable && [RoomStatus.future].includes(room.status));

  return (
    <div>
      <CCol md="12">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <BackToRoomsList />
            <h3>
              {room.name} ({room.status} - {(room.users || []).length ? 'private' : 'public'})
            </h3>
          </div>
          <div>
            {!room.clone && (
              <CButton
                color="secondary"
                style={{ maxWidth: '200px', height: 40, marginRight: 8, marginTop: 15 }}
                onClick={onClone}
              >
                Clone
              </CButton>
            )}
            {changed && (
              <>
                <CButton
                  color="primary"
                  style={{ maxWidth: '200px', height: 40, marginRight: 8, marginTop: 15 }}
                  onClick={room.clone ? onCloneCreate : onSave}
                >
                  Save
                </CButton>

                <CButton
                  color="secondary"
                  style={{ maxWidth: '200px', height: 40, marginRight: 8, marginTop: 15 }}
                  to={{
                    pathname: `/main/rooms`,
                  }}
                >
                  Cancel
                </CButton>
              </>
            )}
            {!room.clone && (
              <CButton
                color="danger"
                style={{ maxWidth: '200px', height: 40, marginRight: 8, marginTop: 15 }}
                onClick={toggleModal}
              >
                Delete
              </CButton>
            )}
            <CModal show={modalOpen} onClose={toggleModal}>
              <CModalHeader closeButton>Confirm Delete</CModalHeader>
              <CModalBody>
                Are you sure you want to delete this room?
                <br /> Write <b>I want to delete room + ID</b>. Example "I want to delete room 45".
                <CInput
                  type="text"
                  placeholder="I want to delete room"
                  value={deleteMessage}
                  onChange={handleChange}
                />
              </CModalBody>
              <CModalFooter>
                <CButton color="danger" onClick={onDelete} disabled={isDeleteAllowed(roomId)}>
                  Delete
                </CButton>
                <CButton color="secondary" onClick={toggleModal}>
                  Cancel
                </CButton>
              </CModalFooter>
            </CModal>
          </div>
        </div>

        <nav
          className="navbar navbar-expand navbar-light bg-light"
          style={{ paddingLeft: 0, backgroundColor: '#e3f2fd', fontSize: '1.4em' }}
        >
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto" style={{ paddingLeft: 0 }}>
              <li className={`nav-item ${tab === 'details' ? 'active' : ''}`}>
                <a
                  className="nav-link"
                  href="#"
                  onClick={() => setTab('details')}
                  style={{ paddingLeft: 0 }}
                >
                  Room details
                </a>
              </li>
              {!room.clone && (
                <li className={`nav-item ${tab === 'users' ? 'active' : ''}`}>
                  <a className="nav-link" href="#" onClick={() => setTab('users')}>
                    Audience
                  </a>
                </li>
              )}
              {(room.status === RoomStatus.future ||
                !!(
                  [RoomStatus.active, RoomStatus.shownNotActive].includes(room.status) &&
                  (room.users || []).length
                )) && (
                  <li className={`nav-item ${tab === 'import-users' ? 'active' : ''}`}>
                    <a className="nav-link" href="#" onClick={() => setTab('import-users')}>
                      Add users
                    </a>
                  </li>
                )}
              {room.status === RoomStatus.active && (
                <li className={`nav-item ${tab === 'current-users' ? 'active' : ''}`}>
                  <a className="nav-link" href="#" onClick={() => setTab('current-users')}>
                    Current users
                  </a>
                </li>
              )}
            </ul>
          </div>
        </nav>

        {tab === 'details' && (
          <div>
            {!room.clone && <Field name="id" value={room.id} />}

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
              <label style={{ fontWeight: 'bold', margin: 0, width: 200 }} className="form-label">
                Room Type
              </label>
              {!room.clone &&
                [RoomStatus.active, RoomStatus.ended, RoomStatus.shownNotActive].includes(
                  room.status
                ) && (
                  <Link
                    to={{
                      pathname: `/main/roomTypes/${room.type}`,
                    }}
                    className="link"
                    style={{ color: '#4A90E2', marginLeft: 10 }}
                  >
                    {room.type}
                  </Link>
                )}
              {shouldShowRoomType && (
                <select
                  className="form-select form-control"
                  style={{ width: 290, marginLeft: 9 }}
                  onChange={e => onTypeChange(parseInt(e.target.value))}
                >
                  {roomTypes.map(rt => (
                    <option value={rt.id} selected={room.type === rt.id}>
                      {rt.name}
                    </option>
                  ))}
                </select>
              )}
              {[RoomStatus.active, RoomStatus.ended].includes(room.status) && (
                <span>
                  &nbsp;({(roomTypes.find(rt => rt.id === room.type) || { name: '' }).name})
                </span>
              )}
            </div>

            <Field {...fieldProps(room, 'name')} />
            <Field {...fieldProps(room, 'description')} />
            {!room.clone && <Field name="status" value={room.status} />}
            {!room.clone && <Field name="createdAt" value={room.createdAt} type="datetime-local" />}
            <Field type="datetime-local" {...fieldProps(room, 'showDate')} />
            <Field type="datetime-local" {...fieldProps(room, 'startDate')} />
            <Field type="datetime-local" {...fieldProps(room, 'endDate')} />
            <Field type="datetime-local" {...fieldProps(room, 'meetupStartDate')} />
            <Field type="datetime-local" {...fieldProps(room, 'meetupEndDate')} />
            {room.clone ? (
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                <label style={{ fontWeight: 'bold', margin: 0, width: 200 }} className="form-label">
                  Active place
                </label>
                <select
                  className="form-select form-control"
                  style={{ width: 290, marginLeft: 9 }}
                  onChange={e => onActivePlaceChange(parseInt(e.target.value))}
                  defaultValue={room.activePlaceId}
                >
                  {activePlaces.map(ap => (
                    <option value={ap.id} selected={room.activePlaceId === ap.id}>
                      {ap.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <Field
                name="activePlaceName"
                value={room.activePlaceName}
                description="default active place"
              />
            )}

            <Field
              name="latitude"
              {...fieldProps(room, 'latitude', room.center ? room.center.latitude : null)}
            />
            <Field
              name="longitude"
              {...fieldProps(room, 'longitude', room.center ? room.center.longitude : null)}
            />
            <Field name="radius" {...fieldProps(room, 'radius')} />
            {room.editable && (
              <p>
                Note: Dates must be provided in UTC format. You can use a{' '}
                <a href="https://dateful.com/convert/utc">tool like this</a> to calculate dates in
                your local zone.
              </p>
            )}
            {room.clone && room.users && (
              <div>
                <label>
                  <input
                    type="checkbox"
                    style={{ marginRight: '4px' }}
                    checked={includeAllowedList}
                    onChange={handleCheckboxChange}
                  />
                  Include allowed-list.
                </label>
                <br />
                <Link onClick={toggleAllowedList}>Click here to toggle the allowed list</Link>
                {showAllowedList && (
                  <div>
                    <h4>Allowed list</h4>
                    <ul>
                      {room.users.map(user => (
                        <li>{user.userId}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {tab === 'users' && (
          <div>
            {!(room.users || []).length ? (
              <p>This is a PUBLIC room</p>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <p>This is a private room with the following users:</p>
                  <CButton
                    color="danger"
                    disabled={room.status !== RoomStatus.future}
                    style={{ maxWidth: '200px', height: 40, marginRight: 8 }}
                    onClick={onDeleteUsers}
                  >
                    Delete users
                  </CButton>
                </div>
                <ul>
                  {room.users.map(u => (
                    <li>
                      <Link
                        to={{
                          pathname: `/main/profile/${u.userId}`,
                          state: { prevLocation: props.location.pathname },
                        }}
                      >
                        {u.userId}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        )}

        {tab === 'import-users' && (
          <div>
            <p>
              Provide a CSV file like{' '}
              <a href="" onClick={template}>
                this template
              </a>{' '}
              in order to add users to this room. Notice that after this action, no other than these
              users will be able to see this room.
            </p>
            <div className="form-group">
              <input
                type="file"
                className="form-control-file"
                id="exampleFormControlFile1"
                onChange={handleFileChange}
              />
              <div>{file && `${humanFileSize(file.size)} ${file.type}`}</div>
            </div>
            {file && (
              <CButton color="primary" style={{ ...baseButton }} onClick={handleUploadClick}>
                Import
              </CButton>
            )}

            {loading && <CSpinner />}

            {report && (
              <div>
                <h4 style={{ marginTop: '20px' }}>Import results</h4>

                <h5>Failures ({report.results.filter(r => r.status === 'fail').length})</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col" style={{ width: '50%' }}>
                        Reason
                      </th>
                      <th scope="col">Body</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(report.results || [])
                      .filter(r => r.status === 'fail')
                      .map((r, i) => (
                        <tr key={i}>
                          <th scope="row">{i}</th>
                          <td>{r.failureReason}</td>
                          <td>
                            <ul style={{ padding: 0 }}>
                              {columns.map(c => (
                                <li style={{ display: 'inline' }}>
                                  <strong>{c}</strong>:{' '}
                                  <span style={{ marginRight: 8 }}>{r.entry[c]}</span>
                                </li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>

                <h5>Success ({report.results.filter(r => r.status === 'success').length})</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      {columns.map(c => (
                        <th scope="col">{c}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {report.results
                      .filter(r => r.status === 'success')
                      .map((r, i) => (
                        <tr key={i}>
                          <th scope="row">{i}</th>
                          {columns.map(c => (
                            <td>{r.entry[c]}</td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}

        {tab === 'current-users' && (
          <div>
            <CButton color="primary" onClick={loadUsers}>
              {currentUsers ? 'Refresh' : 'List'}
            </CButton>

            {currentUsers && (
              <div style={{ marginTop: 10 }}>
                <div>Total: {currentUsers.length}</div>
                <table className="table" style={{ marginTop: 10 }}>
                  <thead>
                    <tr>
                      <th scope="col">userId</th>
                      <th scope="col">Name</th>
                      <th scope="col">Join Date</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentUsers.map(user => (
                      <tr>
                        <td>
                          <Link
                            to={{
                              pathname: `/main/profile/${user.userId}`,
                              state: { prevLocation: props.location.pathname },
                            }}
                          >
                            {user.userId}
                          </Link>
                        </td>
                        <td>{user.name}</td>
                        <td>{user.joinDate}</td>
                        <td>
                          <button
                            type="button"
                            disabled={deleteUserDisabled}
                            style={{ borderRadius: 50, padding: 4 }}
                            class={'btn btn-danger'}
                            onClick={() => onDeleteUser(user.userId)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </CCol>
    </div>
  );
};

const columns = ['userId'];
