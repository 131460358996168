import { CButton, CCol, CSpinner } from '@coreui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as errorModalActions from '../../store/actions/errorModalActions';
import { downloadFile, humanFileSize } from '../../utils/utils';
import { importRooms } from '../../store/actions/roomsActions';
import { BackToRoomsList } from './RoomCommonComponents';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastDefaultConfig } from '../../constants/ToastDefault';

export const RoomsImport = props => {
  const dispatch = useDispatch();
  const baseButton = { maxWidth: '200px' };
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(null);
  const [file, setFile] = useState();

  const handleFileChange = e => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      setFile(file);
      setReport(null);
    }
  };

  const handleUploadClick = async () => {
    if (file.size > 5000000) {
      alert('Cannot upload files bigger than 5 MB. Aborting');
      return;
    }
    const toastId = toast.loading('Importing...', toastDefaultConfig);
    try {
      setLoading(true);
      const results = await importRooms(file);
      setFile(false);
      setReport(results);
      toast.update(toastId, {
        ...toastDefaultConfig,
        render: 'Import success!',
        type: 'success',
        isLoading: false,
      });
    } catch (err) {
      await dispatch(errorModalActions.showModal(err.message));
      toast.update(toastId, {
        ...toastDefaultConfig,
        render: 'Import error',
        type: 'error',
        isLoading: false,
      });
    }
    setLoading(false);
  };

  return (
    <CCol md="12">
      <BackToRoomsList />
      <h3>Import Rooms CSV</h3>
      <p>
        Provide a CSV file like{' '}
        <a href="" onClick={template}>
          this template
        </a>{' '}
        in order to create rooms
      </p>
      <div className="form-group">
        <input
          type="file"
          className="form-control-file"
          id="exampleFormControlFile1"
          onChange={handleFileChange}
        />
        <div>{file && `${humanFileSize(file.size)} ${file.type}`}</div>
      </div>
      {file && (
        <CButton color="primary" style={{ ...baseButton }} onClick={handleUploadClick}>
          Import
        </CButton>
      )}

      <p>
        Note: Dates must be provided in UTC format. You can use a{' '}
        <a href="https://dateful.com/convert/utc">tool like this</a> to calculate dates in your
        local zone.
      </p>

      {loading && <CSpinner />}

      {report && (
        <div style={{ overflow: 'scroll' }}>
          <h4 style={{ marginTop: '20px' }}>Import results</h4>

          <h5>Failures ({report.results.filter(r => r.status === 'fail').length})</h5>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col" style={{ width: '50%' }}>
                  Reason
                </th>
                <th scope="col">Body</th>
              </tr>
            </thead>
            <tbody>
              {(report.results || [])
                .filter(r => r.status === 'fail')
                .map((r, i) => (
                  <tr key={i}>
                    <th scope="row">{i}</th>
                    <td>{r.failureReason}</td>
                    <td>
                      <ul style={{ padding: 0 }}>
                        {columns.map(c => (
                          <li style={{ display: 'inline' }}>
                            {c === 'invitedUsers' && r.entry[c] ? (
                              <>
                                {' '}
                                <strong>{c}</strong>:{' '}
                                <span style={{ marginRight: 8 }}>
                                  {r.entry[c].map(user => (
                                    <Link
                                      to={{
                                        pathname: `/main/profile/${user.userId}`,
                                      }}
                                      className="link"
                                      style={{ color: '#4A90E2', marginLeft: 8 }}
                                    >
                                      {user.userId}
                                    </Link>
                                  ))}
                                </span>
                              </>
                            ) : (
                              <>
                                {' '}
                                <strong>{c}</strong>: {r.entry[c]}
                              </>
                            )}
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <h5>Success ({report.results.filter(r => r.status === 'success').length})</h5>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Id</th>
                {columns.map(c => (
                  <th scope="col">{c}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {report.results
                .filter(r => r.status === 'success')
                .map((r, i) => (
                  <tr key={i}>
                    <th scope="row">
                      <Link
                        to={{
                          pathname: `/main/rooms/${r.entry.id}`,
                        }}
                        className="link"
                        style={{ color: '#4A90E2' }}
                      >
                        {r.entry.id}
                      </Link>
                    </th>
                    {columns.map(c => (
                      <td>
                        {c === 'invitedUsers'
                          ? (r.entry[c] || []).map(e => (
                              <>
                                <Link
                                  to={{
                                    pathname: `/main/profile/${e.userId}`,
                                  }}
                                  className="link"
                                  style={{ color: '#4A90E2' }}
                                >
                                  {e.userId}
                                </Link>
                                ,{' '}
                              </>
                            ))
                          : r.entry[c]}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </CCol>
  );
};

const columns = [
  'name',
  'type',
  'description',
  'showDate',
  'startDate',
  'endDate',
  'meetupStartDate',
  'meetupEndDate',
  'activePlaceId',
  'latitude',
  'longitude',
  'radius',
  'invitedUsers',
];

function template() {
  downloadFile(
    `
${columns.join(',')}
nyc public,1,nyc public,2023-08-08 12:00:00,2023-08-08 13:00:00,2023-08-08 15:00:00,2023-08-08 18:00:00,2023-08-08 20:00:00,1,,,,
particular place,1,a room in a particular nyc neighborhood,2024-08-08 12:00:00,2024-08-08 13:00:00,2024-08-08 15:00:00,2024-08-08 18:00:00,2024-08-08 20:00:00,1,26.015818119049072,-80.1872992515564,10000,
private,1,a private room where only some users can enter,2023-08-08 12:00:00,2023-08-08 13:00:00,2023-08-08 15:00:00,2023-08-08 18:00:00,2023-08-08 20:00:00,1,,,,"a359e172-5816-451d-8f23-d39fb86e1259,cac0e549-cade-4b96-967b-4641331bcf34"
  `.trim(),
    'motto-rooms-template.csv',
    'text/csv'
  );
}
