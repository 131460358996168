import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react';
import printDateWithTz from '../../utils/dateUtils';

export function BackToRoomsList() {
  return (
    <Link
      to={{
        pathname: `/main/rooms`,
      }}
      className="link"
      style={{ color: '#4A90E2' }}
    >
      Back to list
    </Link>
  );
}

export function BackToRoomTypesList() {
  return (
    <Link
      to={{
        pathname: `/main/roomTypes`,
      }}
      className="link"
      style={{ color: '#4A90E2' }}
    >
      Back to list
    </Link>
  );
}

export function Field({ name, value, description, editable, type, onChange, useOnBlur, timezone }) {
  const formattedValue = type === 'datetime-local' ? dateToHTML(value, timezone, editable) : value;
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
      <label
        style={{ fontWeight: 'bold', margin: 0, width: 200 }}
        htmlFor={name}
        className="form-label"
      >
        {name}
      </label>

      {editable && (
        <input
          style={{ marginLeft: 8, flexGrow: 1, maxWidth: 300 }}
          type={type || 'text'}
          className="form-control"
          id={name}
          defaultValue={formattedValue}
          {...(useOnBlur ? { onBlur: onChange } : { onChange })}
        />
      )}

      {!editable && <span style={{ marginLeft: 8, flexGrow: 1 }}>{formattedValue}</span>}
    </div>
  );
}

export function dateToHTML(date, timezone, editable) {
  if (!date) {
    return '';
  }

  let formattedDate;
  if (date.endsWith('.000Z')) {
    formattedDate = date.substring(0, date.length - '.000Z'.length);
  }
  if (timezone && !editable) {
    formattedDate = printDateWithTz(date, timezone);
  }
  return formattedDate;
}

const DeleteRoomComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleDelete = () => {
    toggleModal();
  };

  return (
    <>
      <CButton
        color="danger"
        style={{ maxWidth: '200px', height: 40, marginRight: 8, marginTop: 15 }}
        onClick={toggleModal}
      >
        Delete
      </CButton>
      <CModal show={modalOpen} onClose={toggleModal}>
        <CModalHeader closeButton>Confirm Delete</CModalHeader>
        <CModalBody>Are you sure you want to delete this room?</CModalBody>
        <CModalFooter>
          <CButton color="danger" onClick={handleDelete}>
            Delete
          </CButton>
          <CButton color="secondary" onClick={toggleModal}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default DeleteRoomComponent;
